import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieTesterService {

  static checkCookies() {
    document.cookie = 'rv-test-local-cookie=yes';
    if (document.cookie.indexOf('rv-test-local-cookie') > -1) {
      return Promise.resolve();
    }

    return Promise.reject();

  };
}
