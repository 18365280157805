import { Component, OnInit, TemplateRef } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

import { LoadingService, UserAuthFactory } from 'src/app/ajs-upgraded-providers';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';

import { UserStateService } from 'src/app/auth/services/user-state.service';
import { RvshareAppDetectionService } from 'src/app/screen-sharing/services/rvshare-app-detection.service';

@Component({
  selector: 'auth-buttons',
  templateUrl: './auth-buttons.component.html',
  styleUrl: './auth-buttons.component.scss'
})
export class AuthButtonsComponent implements OnInit {

  get isScreenShareApp(): any {
    return this.rvShareAppDetection.isScreenShareApp();
  }

  get userPicture() {
    return this.userStateService.getUserPicture();
  }

  get companyName() {
    return this.companyStateService.getUserCompanyName();
  }

  get username() {
    return this.userStateService.getUsername();
  }

  get isAssignedPublisher() {
    return this.userStateService.isAssignedPublisher();
  }

  constructor(
    private loadingService: LoadingService,
    private rvShareAppDetection: RvshareAppDetectionService,
    private userStateService: UserStateService,
    private companyStateService: CompanyStateService,
    public actionSheet: MatBottomSheet,
    private userAuthFactory: UserAuthFactory
  )
  { }

  ngOnInit(): void {
    this.loadingService.startGlobal('auth-buttons-silent');
    this.userAuthFactory.authenticate(false).finally(() => {
      this.loadingService.stopGlobal('auth-buttons-silent');
    });
  }

  openMobileMenu(template: TemplateRef<any>) {
    this.actionSheet.open(template, { panelClass: 'auth-buttons-action-sheet' });
  }
}
