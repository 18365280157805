<li class="dropdown-header" [shown]="!companyStateService.isSubcompanySelected()">
  <p>{{userStateService.getUsername()}}</p>
  <p><strong class="mt-2">{{companyStateService.getSelectedCompanyName()}}</strong>
    <span class="text-muted px-2" [shown]="!userStateService.isAssignedPublisher()">|</span>
    <a id="select-subcompany-button" class="madero-link" href="#" (click)="selectCompany()" [shown]="!userStateService.isAssignedPublisher()">Select Sub-Company</a>
  </p>
</li>
<li class="dropdown-header sub-company-header" [shown]="companyStateService.isSubcompanySelected()">
  <p>You are in Sub-Company <strong>{{companyStateService.getSelectedCompanyName()}}</strong>
    <span class="text-muted px-2" [shown]="!userStateService.isAssignedPublisher()">|</span>
    <a id="change-subcompany-button" class="madero-link" href="#" (click)="selectCompany()" [shown]="!userStateService.isAssignedPublisher()">Change</a>
  </p>
  <p [shown]="!userStateService.isAssignedPublisher()"><a id="reset-subcompany-button" class="madero-link" href="#" (click)="companyStateService.resetCompany()">Switch to My Company</a></p>
</li>
<mat-nav-list>
  <hr class="my-3">
  @if (!isScreenShareApp()) {
    <a mat-list-item id="add-subcompany-menu-button" [shown]="(userStateService.isRiseAdmin() || userStateService.isUserAdmin())" class="action add-subcompany-menu-button" uiSref="apps.company.add">
      <streamline-icon aria-hidden="true" name="add" width="14" height="14"></streamline-icon>
      <span class="item-name pl-2">Add Sub-Company</span>
    </a>

    <a mat-list-item id="company-settings-menu-button" [shown]="userStateService.isRiseAdmin() || userStateService.isUserAdmin()" uiSref="apps.company.details" class="action company-settings-menu-button">
      <streamline-icon aria-hidden="true" name="building" width="14" height="14"></streamline-icon>
      <span class="item-name pl-2">Company Settings</span>
    </a>
    <a mat-list-item [shown]="companyAccessService.accessCompanyLicenses" uiSref="apps.company.licenses" data-toggle="modal" class="action company-licenses-menu-button">
      <streamline-icon aria-hidden="true" name="hierarchy" width="14" height="14"></streamline-icon>
      <span class="item-name pl-2">Companies &amp; Licenses</span>
    </a>
    <a mat-list-item [shown]="userStateService.isRiseAdmin() || userStateService.isUserAdmin()" uiSref="apps.user.list" data-toggle="modal" class="action company-users-menu-button">
      <streamline-icon aria-hidden="true" name="users" width="14" height="14"></streamline-icon>
      <span class="item-name pl-2">Company Users</span>
    </a>
  }
</mat-nav-list>

