<div id="commonHeaderDiv" [hidden]="hideCommonHeader">
	<!-- Plan Banner -->
	<plan-banner [hidden]="isAssignedPublisher"></plan-banner>
	<!-- END Plan Banner -->

	<!-- Common Header Navbar -->
	<nav class="navbar navbar-default hidden-print" role="navigation">
			<div class="navbar-header">

        @if (!isScreenShareApp) {
          <a class="navbar-brand visible-md visible-lg" href="/">
            <img loading="lazy" src="//s3.amazonaws.com/Rise-Images/UI/logo.svg" class="img-responsive logo-small" width="113" height="42" alt="Rise Vision">
          </a>
          <a class="navbar-brand hidden-md hidden-lg text-center" id="mobileMenuButton" (click)="openMobileMenu(mobileMenu)">
            <i class="fa fa-bars"></i>
          </a>
        } @else {
          <a class="navbar-brand" href="/join">
            <img loading="lazy" src="//s3.amazonaws.com/Rise-Images/UI/logo.svg" class="img-responsive logo-small" width="113" height="42" alt="Rise Vision">
          </a>
        }

				<!-- Nav Links -->
        @if (ENV_NAME) {
          <div class="navbar-left visible-xs visible-sm">
            <ul class="nav navbar-nav">
              <li class="env-name-nav-item">
                <span class="env-name-label">{{ENV_NAME}}</span>
              </li>
            </ul>
          </div>
        }

				<div class="navbar-collapse navbar-left hidden-xs hidden-sm">
					<ul id="commonHeaderNav" class="nav navbar-nav">
            @if (ENV_NAME) {
              <li class="env-name-nav-item visible-md visible-lg">
                <span class="env-name-label">{{ENV_NAME}}</span>
              </li>
            }
            @for (opt of navOptions; track opt) {
              @if (showMenuItem(opt) && !isScreenShareApp) {
                <li>
                  @if (opt.title !== 'Screen Share' || isFeatureAvailable('screen_sharing')) {
                    <a id="{{opt.title + 'Link'}}" [uiSref]="opt.link" [ngClass]="{'selected': opt.states && opt.states.indexOf(navSelected) > -1}">{{opt.title}}</a>
                  } @else {
                    <a id="{{opt.title + 'Link'}}" style="display: flex" class="flex-row" href="" (click)="upgradePlan()">
                      {{opt.title}}
                      <streamline-icon name="circleArrowUp" class="rise-blue u_lh-1 ml-2" width="16" height="16"></streamline-icon>
                    </a>
                  }
                </li>
              }
            }
            @if (!isScreenShareApp) {
              <li>
                <a id="trainingLink" href="https://risevision.com/webinars?utm_source=app&utm_medium=web&utm_campaign=in_app_training_notification#weekly-training" target="_blank">Training</a>
              </li>
            }
					</ul>
				</div>


				<!-- Action Nav -->
				<ul class="nav navbar-nav navbar-right actions-nav pull-right u_remove-right">
          @if (!isScreenShareApp) {
            <!-- Help Widget -->
            <help-widget-button></help-widget-button>

            <!-- Appcues Launchpad -->
            <li id="appcues-launchpad"></li>
          }

					<!-- Auth -->
          <auth-buttons></auth-buttons>
				</ul>
				<!-- END Action Nav -->

				<!-- END Nav Links -->
			</div>

      <company-banner></company-banner>
	</nav>

	<!-- END Common Header Navbar -->

	<!-- Off Canvas Version of the Nav -->
</div>

<ng-template #mobileMenu>
  <div class="tw-flex tw-justify-between tw-items-center tw-p-4">
    <img loading="lazy" src="//s3.amazonaws.com/rise-common/images/logo-small.png" class="img-responsive logo-small" width="113" height="42" alt="Rise Vision">
    <button mat-icon-button id="close-button" (click)="bottomSheetRef.dismiss()">
      <i class="fa fa-times fa-2x"></i>
    </button>
  </div>
  <mat-nav-list class="off-canvas-nav hidden-md hidden-lg">
    @for (opt of navOptions; track opt) {
      @if (showMenuItem(opt)) {
        <mat-list-item>
          @if (opt.title !== 'Screen Share' || isFeatureAvailable('screen_sharing')) {
            <a [uiSref]="opt.link">{{opt.title}}</a>
          } @else {
            <a style="display: flex" class="flex-row" href="" ng-click="upgradePlan()">
              {{opt.title}}
              <streamline-icon name="circleArrowUp" class="rise-blue u_lh-1 ml-2" width="16" height="16"></streamline-icon>
            </a>
          }
        </mat-list-item>
      }
    }
    <mat-list-item>
      <a id="trainingLink" href="https://risevision.com/webinars?utm_source=app&utm_medium=web&utm_campaign=in_app_training_notification#weekly-training" target="_blank">Training</a>
    </mat-list-item>
  </mat-nav-list>
</ng-template>

@if (!cookieEnabled) {
  <div class="bg-warning text-center u_padding-sm">
    <small><strong>Cookies Are Disabled.</strong> Rise Vision needs to use cookies to properly function. Please enable cookies in your web browser and refresh this page.</small>
  </div>
}

